#profile {
    padding-top: 0;
    @media (max-width: 991px) {
        padding-top: 0;
    }
    &.my-orders {
        max-width: 80rem;
    }
}

#profile-nav {
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 3rem;

    a {
        margin: .5rem .8rem;
    }
}

#profile-subnav {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
        border-bottom: 1px solid #dddddd;

        &:last-child {
            margin-bottom: 0;
            border-bottom: 0;
        }

        &.active {
            position: relative;

            .item-name {
                font-weight: 800;
            }

            &::after {
                content: '';
                position: absolute;
                top: 50%;
                right: 1rem;
                width: 1rem;
                height: 1rem;
                border: 1px solid #c1c1c1;
                border-left: 0;
                border-bottom: 0;
                pointer-events: none;
                transform: translateY(-50%) rotate(45deg);
            }
        }

        a {
            display: block;
            padding: 0.5rem 2rem 0.5rem 0.5rem;
        }
    }

    .item-name {
        display: block;
        font-size: 1.1rem;
        font-weight: 600;
    }

    .item-adress {
        display: block;
        font-size: .9rem;
        opacity: .8;
    }
}

.order-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0.4rem 0;
    color: $text-color;
    border-bottom: 1px solid rgba($primary-color, .3);
    transition: background-color .3s;
    &:last-child {
        border-bottom: 0;
    }
    //&:hover {
    //    background: darken($text-color,1%);
    //}
    div {
        padding: .5rem 1rem;
        width: 25%;
    }
    span {
        display: block;
    }
    .order-date,
    .order-price {
        text-align: right;
    }
    .order-id {
        color: #adadad;
    }
    .order-price {
        color: #8bc34a;
        font-weight: 700;
    }
    .order-status {
        font-weight: 700;
        span {
            width: 6rem;
            text-align: center;
            padding: 0.6rem 0.3rem;
            border-radius: 10px;
            margin-left: auto;
            font-size: .9rem;
            line-height: 1;
        }
    }
    a {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    .order-item {
        display: flex;
        align-items: center;
        width: 100%;
        span {
            &:nth-child(1) {
                font-weight: 700;
                min-width: 11rem;
                width: 30%;
            }
        }
        .order-value {
            //overflow-x: auto;
        }
    }
    @media (max-width: 767px) {
        flex-direction: column;
        justify-content: center;
        width: 100%;
        padding: .8rem 0;
        .order-id,
        .order-date,
        .order-price,
        .order-status {
            text-align: center;
        }
        .order-status {
            span {
                margin: 0 auto;
            }
        }
        div {
            width: 100%;
        }
    }
}

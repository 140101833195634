// -----------------------------------------------------------------------------
// This file contains variables
// -----------------------------------------------------------------------------

$text-font: futura-pt, sans-serif;


$primary-color: #857654;
$text-color: #221C18;
$white: #fff;
$nav-color: #221C18;
$grey-bg: #F9FAFB;
$red: #D42C2C;
$green: #61B04D;

$min-width: 320px;
$max-width: 1320px;

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1400px
);

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 950px,
    xl: 1120px,
    xxl: 1380px
);

$min-vw: 320px;
$max-vw: 1400px;

$grid-columns: 60;

$enable-negative-margins: true;

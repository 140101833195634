#hero-slider {
    position: relative;

    .slider-top-editor {
        color: $white !important;
        p, span, div {
            font-size: rem(18px);
            color: $white;
        }

        p {
            @include media-breakpoint-down(sm) {
                padding-right: 5rem;
            }
        }
    }
}


.categories {
    padding: 40px 0;
    background-color: $grey-bg;
}

.newsletter {
    background: linear-gradient(to bottom, rgba(229,227,221,1), rgba(229,227,221,0));
    position: relative;
    .container {
        position: relative;
        z-index: 2;
        padding: 130px 15px;
        @include media-breakpoint-down(lg) {
            padding: 80px 15px 0 15px;
        }
        .img, picture {
            position: absolute;
            right: -100px;
            bottom: 0;
            width: 100%;
            max-width: 780px;
            z-index: 2;
            @include media-breakpoint-down(lg) {
                position: relative;
                right: 0;
            }
        }
    }
    &:before {
        content: " ";
        position: absolute;
        top: 0;
        left: 0;
        width: 50vw;
        height: 100%;
        background: linear-gradient(to right, rgba(240,232,207,1), rgba(255,255,255,0));
        z-index: 1;
    }

    .form {
        margin: 30px 0;
        max-width: 460px;
        position: relative;
        button {
            position: absolute;
            top: 0;
            right: 0;
            height: 58px;
            padding: 0 30px;
            text-transform: uppercase;
            letter-spacing: 1.2px;
            color: #fff;
            background-color: #221c18;
            border-radius: 30px;
            font-weight: 500;
            font-size: .875rem;
        }
        input {
            width: 100%;
            height: 58px;
            background-color: $white;
            border-radius: 30px;
            padding: 0 125px 0 30px;
            border: none;
            outline: none;
        }
    }

    input[type="checkbox"] {
        display: none;
        &:checked + label span {
            background-color: $text-color;
            border-color: transparent;
            img {
                display: block;
            }
        }
    }

    label[for="gdpr"] {
        cursor: pointer;
        font-size: rem(16px);
        color: #838384;
        position: relative;
        padding-left: 32px;
        span {
            position: absolute;
            left: 0;
            top: -4px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 22px;
            height: 22px;
            border: 1px solid #838384;
            border-radius: 8px;
            margin-right: 10px;
            transform: translateY(5px);
            img {
                width: 15px;
                display: none;
            }
        }
        a {
            color: #857654;
            text-decoration: underline !important;
        }
    }
}
/**
    BOTTOM SLIDER
 */

.bottom-slider {
    padding: 10vw 0;
    margin-bottom: 100px;
    .slider-wrap {
        position: absolute;
        right: 0;
        width: 57%;
        height: 100%;
        top: 50%;
        transform: translateY(-50%);
        padding: 30px 0;
        display: flex;
        align-items: center;
        @include media-breakpoint-down(lg) {
            position: relative;
            width: 100%;
            height: auto;
            top: 0;
            transform: translateY(0);
            .swiper {
                padding: 0 10%;
            }
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position:50% 50%;
        }
    }
    a {
        height: 54px;
        line-height: 54px;
        display: inline-block;
        position: relative;
        text-transform: uppercase;
        color: $nav-color;
        font-weight: 500;
        font-size: rem(14px);
        padding: 0 80px 0 80px;
        letter-spacing: 1.2px;
        &:hover {
            &:before {
                width: 100%;
            }
            &:after {
                left: calc(100% - 40px);
            }
            color: $white;
        }
        span {
            position: relative;
            z-index: 2;
        }
        &:before {
            content: " ";
            width: 54px;
            height: 54px;
            border-radius: 28px;
            background-color: $nav-color;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 1;
            transition: width .3s ease-in-out;
        }

        &:after {
            content: "";
            width: 16px;
            height: 12px;
            background: url('/img/white-right-arrow.svg');
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 20px;
            z-index: 2;
            transition: left .3s ease-in-out;
        }
    }
}

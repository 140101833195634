.filter {
    background-color: #F9F9F9;
    padding: 24px 42px;
    margin-bottom: 25px;
    @include media-breakpoint-down(sm) {
        padding: 24px;
    }
    button:not(.dropdown-menu button) {
        font-size: rem(15px);
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: 1px;
        position: relative;
        padding-right: 16px;
    }

    &--title {
        font-size: rem(15px);
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: 1px;
        position: relative;
        padding-right: 16px;
    }

    .dropdown-toggle.show:before {
        transform: rotate(180deg);
    }


    .dropdown-toggle:after {
        display: none;
    }

    .dropdown-toggle:before {
        content: " ";
        width: 8px;
        height: 5px;
        background: url("/img/angle-down.svg");
        position: absolute;
        right: 0;
        top: 8px;
        transition: transform .2s ease-in-out;
    }

    .dropdown-menu {
        white-space: nowrap;
        border-radius: 0;
        padding: 15px;
        li button {
            width: 100%;
            text-align: left;
            padding: 0 3px;
            &:hover {
                background-color: $grey-bg;
            }
            &.selected {
                background-color: $grey-bg;
            }
        }
        li:not(:last-child) {
            margin-bottom: 5px;
        }
        &.show {
            animation: fadeIn .3s forwards;
        }
    }

    input[type="checkbox"] {
        display: none;
        &:checked + label span {
            background: $primary-color;
            border-color: transparent;
            &:before {
                opacity: 1;
            }
        }
    }

    label {
        cursor: pointer;
        display: flex;
        align-items: center;
        span {
            display: inline-block;
            width: 20px;
            height: 20px;
            border:1px solid darken($grey-bg,10%);
            margin-right: 10px;
            position: relative;
            &:before {
                content: " ";
                width: 13px;
                height: 13px;
                background: url("/img/check.svg");
                position: absolute;
                top: 3px;
                left: 2px;
                opacity: 0;
            }
        }
    }
}

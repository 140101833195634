#cart-steps {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 4rem;
    @media (max-width: 767px) {
        justify-content: center;
        align-items: flex-start;
        max-width: 40rem;
        margin-left: auto;
        margin-right: auto;
    }
    .cart-steps-item {
        text-align: center;
        margin: 0 1rem;
        &.active {
            .cart-steps-item-number {
                background: $primary-color;
                border-color: $primary-color;
                color: $text-color;
            }
            img {
                filter: invert(1);
            }
            i {
                color: inherit;
            }
        }
    }
    .cart-steps-item-number {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 3.75rem;
        height: 3.75rem;
        line-height: 3.75rem;
        background: $text-color;
        border: 1px solid $text-color;
        color: $primary-color;
        font-size: 1.1rem;
        font-weight: 900;
        border-radius: 1.1rem;
        box-shadow: 0 0 16px rgba(0,0,0,.22);
        margin: auto;
        .a {
            display: initial;
        }
        &.disabled {
            background: transparent;
            color: $text-color;
            border: 1px solid $primary-color;
        }
    }
    img {
        width: 2rem;
        height: 2rem;
        display: initial;
    }
}

.testimonial {
    padding: 90px 0;
    background: #221C18;

    @include media-breakpoint-down(lg) {
        padding: 60px 0;
    }

    @include media-breakpoint-down(md) {
        padding: 40px 0;
    }



    &-body {
        padding-inline: 100px;
        padding-bottom: 50px;
        color: #E9DFCD;
        font-size: 26px;
        font-weight: 500;
        line-height: normal;
        text-align: center;

        @include media-breakpoint-down(lg) {
            font-size: 22px;
        }

        @include media-breakpoint-down(md) {
            padding-inline: 60px;
            font-size: 18px;
        }
    }


    &-name {
        margin-top: 42px;
        font-size: 20px;
        font-weight: 450;

        @include media-breakpoint-down(lg) {
            margin-top: 32px;
        }

        @include media-breakpoint-down(md) {
            margin-top: 22px;
        }

        span {
            color: rgba(233, 223, 205, 0.30);
            text-transform: uppercase;
        }
    }

    .swiper {
        --swiper-pagination-color: #E7E3DB;
        --swiper-pagination-bullet-inactive-color: #5E574E;

        &-button-prev,
        &-button-next {
            top: 25%;
            width: 58px;
            height: 58px;
            background: #463C36;
            border-radius: 50%;

            @include media-breakpoint-down(md) {
                top: 30%;
                width: 40px;
                height: 40px;
            }

            &::after {
                color: rgba(255, 255, 255, .5);
                font-size: 18px;

                @include media-breakpoint-down(md) {
                    font-size: 16px;
                }
            }
        }

        &-pagination {
            bottom: 0 !important;
        }
    }

    &.light {
        background: #E9DFCD;

        .testimonial {
            &-body {
                color: #221C18;
            }

            &-name {

                span {
                    color: rgba(34, 28, 24, 0.30);
                }
            }
        }

        .swiper {
            --swiper-pagination-color: #221C18;
            --swiper-pagination-bullet-inactive-color: rgba(94, 87, 78, 1);
        }
    }
}

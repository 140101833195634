.product-detail {
    background-color: $white;
    padding-bottom: 85px;
}

.product-slider {
    position: relative;
}
.labels {
    position: absolute;
    top: 25px;
    left: 20px;
    z-index: 10;
    li {
        font-size: rem(14px);
        font-weight: 500;
        color: $white;
        text-transform: uppercase;
        letter-spacing: 1px;
        padding: 0 10px;
        border-radius: 20px;
        &.green {
            background-color: #61b04d;
        }

        &.orange {
            background-color: #ffa800;
        }
    }

    &.labels-sm {
        li {
            font-size: .75rem;
            letter-spacing: 1px;
            font-weight: 600;
            padding: 0 10px;
            display: block;
            border-radius: 30px;
        }
    }
}

.main-slider {
    .swiper-button-prev {
        @include media-breakpoint-up(md) {
            left: 30px;
        }
    }

    .swiper-button-next {
        @include media-breakpoint-up(md) {
            right: 30px;
        }
    }
    .swiper-button-prev,
    .swiper-button-next {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 46px;
        height: 46px;
        border: 1px solid rgba(155, 157, 161, 0.2);
        background: $white;
        border-radius: 50%;
        &:after {
            font-size: rem(14px);
            color: #9b9da1;
        }
    }
}

.product-text {
    padding-top: 30px;
    @include media-breakpoint-up(lg) {
        padding-left: 60px;
    }
    h4 {
        font-weight: 500;
        font-size: rem(18px);
    }

    h3 {
        font-weight: 500;
        font-size: rem(36px);
    }

    .category {
        font-weight: 500;
        font-size: rem(14px);
        text-transform: uppercase;
        color: $white;
        background-color: #71c3dd;
        padding: 3px 10px;
        border-radius: 16px;
        letter-spacing: 2px;
    }

    p {
        color: rgba(12, 12, 12, 0.5);
        margin-top: 20px;
    }

    a {
        font-size: rem(12px);
        text-transform: uppercase;
        font-weight: 500;
        color: $primary-color;
        letter-spacing: 2px;
        border-bottom: 1px solid rgba($primary-color, 0.14);
    }

    .buy-box {
        background-color: #f6f6f6;
        border: 1px solid #f4f4f4;
        margin-top: 30px;
        padding: 28px;
        @include media-breakpoint-up(lg) {
            max-width: 348px;
        }
        .old-price {
            font-size: rem(14px);
            font-weight: 500;
            color: #bdbdbd;
            text-decoration: line-through;
        }
        .price-info {
            font-size: rem(14px);
            font-weight: 500;
            color: rgba(12, 12, 12, 0.5);
            line-height: 1.7;
        }

        .price {
            font-weight: 500;
            font-size: rem(30px);
            color: #d42c2c;
            display: block;
            line-height: normal;
            margin-right: 10px;
        }
    }
}

.buy-box-button {
    background-color: #61b04d;
    font-weight: 500;
    font-size: rem(14px);
    text-transform: uppercase;
    letter-spacing: 2px;
    color: $white;
    padding: 0 50px;
    height: 58px;
    border-radius: 30px;
    margin-top: 15px;
    span {
        position: relative;
        top: 5px;
        left: 5px;
        &:after {
            content: " ";
            width: 10px;
            height: 9px;
            background: url("/img/right-arrow.svg");
            position: absolute;
            top: 0;
            left: 0;
        }
    }
    &:hover {
        background-color: darken(#61b04d, 10%);
    }
}

.product-description {
    background-color: #f9f9f9;
    padding: 23px 0 70px 0;
    ul {
        position: relative;
        border-bottom: 1px solid #e5e7eb;
        &:before,
        &:after {
            content: "";
            width: 100%;
            height: 1px;
            background-color: #e5e7eb;
            position: absolute;
            bottom: 0;
        }

        &:before {
            right: 100%;
        }

        &:after {
            left: 100%;
        }
        li {
            font-size: rem(18px);
            font-weight: normal;
            padding-bottom: 15px;
            border-bottom: none;
            transition: all 0.4s;

            &.selected {
                font-weight: 500;
                border-bottom: 2px solid $primary-color;
            }

            &:hover {
                cursor: pointer;
                font-weight: 500;
                border-bottom: 2px solid $primary-color;
            }
        }
    }

    .description {
        padding-top: 50px;
    }
    p {
        font-size: rem(18px);
        margin-bottom: 0.5rem;
    }
}

.buy-now-form {
    position: relative;

    .close-button {
        position: absolute;
        top: 0;
        right: 0;

        width: 1.5rem;
        height: 1.5rem;
        color: lighten($text-color, 50%);
        transition: all 0.6s;

        svg {
            fill: currentColor;
            width: 100%;
            height: 100%;
        }

        &:hover {
            color: $text-color;
        }
    }

    .form-input {
        display: flex;
        flex-flow: column;

        input,
        textarea {
            border-top: none;
            border-left: none;
            border-right: none;
            border-bottom: rem(2px) solid lighten($primary-color, 10%);
            outline: none;
            width: 100%;
            min-width: 0;
            z-index: 1;
            position: relative;
            vertical-align: middle;
            line-height: rem(18px);
            margin: 0 0 rem(6px) 0;
            font-size: rem(18px);
            -webkit-appearance: none;
            transition: all 0.6s;
            color: $text-color;
            cursor: text;
            background-color: transparent;
            padding: rem(4px) rem(8px) rem(8px) rem(8px);

            &:focus {
                border-color: $text-color;
            }

            &.is-invalid {
                border-color: $red;
            }
        }
    }

    .h4,
    label {
        font-weight: 500;
        font-size: rem(18px);
    }

    h3,
    .h3 {
        font-weight: 500;
        font-size: rem(36px);
    }

    .buy-button {
        background-color: $green;
        font-weight: 500;
        font-size: 0.875rem;
        text-transform: uppercase;
        letter-spacing: 2px;
        color: $white;
        width: 100%;
        height: 58px;
        border-radius: 30px;
        margin-top: 15px;

        span {
            position: relative;
            top: 5px;
            left: 5px;
            &:after {
                content: " ";
                width: 10px;
                height: 9px;
                background: url("/img/right-arrow.svg");
                position: absolute;
                top: 0;
                left: 0;
            }
        }
        &:hover {
            background-color: darken($green, 10%);
        }
    }
}

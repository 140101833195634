.our-store {
    .grid-container {
        display: grid;
        //max-width: 25rem;
        //grid-auto-rows: 17rem;
        grid-template-columns: auto auto;
        width: 100%;
        height: 100%;

        .grid-item {
            background-color: transparent;
            text-align: center;
            //padding: 3px;
            width: 100%;
            height: 100%;
            overflow: hidden;

            .image {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }

        &.grid-container-1 {
            //grid-template-columns: auto auto;
            grid-auto-rows: 34rem;
        }

        &.grid-container-2 {
            //grid-template-columns: auto auto;
        }

        &.grid-container-3 {
            .item2 {
                grid-row-start: span 2;
            }
        }
    }

    //.image {
        //max-width: 25rem;
        //width: auto;
        //max-height: 34rem;
    //}
}

.results {
    p {
        font-size: rem(18px);
        font-weight: 500;
        color: #BABABA;
        span {
            color: #221C18;
        }
    }
}

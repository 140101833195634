.blog {
    padding: 60px 0;

    @include media-breakpoint-down(lg) {
        padding: 45px 0;
    }

    @include media-breakpoint-down(md) {
        padding: 35px 0;
    }

    .btn-category,
    .btn-show-more {
        height: 58px;
        padding: 20px 32px;
        background: #EBEEF1;
        border: 0;
        border-radius: 30px;
        color: #4D4D4D;
        font-size: 14px;
        font-weight: 450;
        line-height: normal;
        letter-spacing: 1.68px;
        text-transform: uppercase;

        &:hover,
        &:focus {
            background-color: darken(#EBEEF1, 10%);
        }

        &.active {
            background: #61B04D;
            color: #FFF;
        }
    }

    .btn-show-more {
        background: #61B04D;
        color: #FFF;

        &:hover,
        &:focus {
            background-color: darken(#61B04D, 10%);
            color: #FFF;
        }
    }

    &-slider {
        padding: 60px 0;
        background: #463C36;

        @include media-breakpoint-down(lg) {
            padding: 45px 0;
        }

        @include media-breakpoint-down(md) {
            padding: 35px 0;
        }

        h2 {
            color: #F0E8CF;
        }

        &.light {
            background: #E9DFCD;

            h2 {
                color: #000;
            }
        }
    }

    &-thumb {
        background: #FFF;
        border-radius: 15px;
        overflow: hidden;

        &:hover,
        &:focus {
            .blog-thumb-image img {
                transform: scale(1.15);
            }
        }

        &-image {
            display: block;
            overflow: hidden;

            img {
                width: 100%;
                height: 210px;
                object-fit: cover;
                object-position: center center;
                transition: transform .3s ease-in-out;

                @include media-breakpoint-down(md) {
                    // height: auto;
                }
            }
        }

        &-content {
            padding: 32px;

            @include media-breakpoint-down(lg) {
                padding: 20px;
            }
        }

        &-date {
            margin-bottom: 10px;
            color: #F07C63;
            font-size: 12px;
            font-weight: 600;
            line-height: normal;
            letter-spacing: 1.92px;
            text-transform: uppercase;
        }

        h3 {
            color: #4D4D4D;
            font-size: 20px;
            font-weight: 450;
            line-height: normal;

            @include media-breakpoint-down(md) {
                font-size: 18px;
            }
        }

        a {
            color: #4D4D4D;

            &:hover,
            &:focus {
                color: #857654;
            }
        }
    }

    &-gutter {
        --bs-gutter-x: 90px;
        --bs-gutter-y: 90px;

        @include media-breakpoint-down(xxl) {
            --bs-gutter-x: 70px;
            --bs-gutter-y: 70px;
        }

        @include media-breakpoint-down(lg) {
            --bs-gutter-x: 30px;
            --bs-gutter-y: 30px;
        }

        @include media-breakpoint-down(md) {
            --bs-gutter-x: 24px;
            --bs-gutter-y: 24px;
        }
    }
}

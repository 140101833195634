.subpage {
    padding: 80px 0;

    @include media-breakpoint-down(lg) {
        padding: 60px 0;
    }

    @include media-breakpoint-down(md) {
        padding: 40px 0;
    }

    &.subpage-sm {
        padding: 60px 0;
    }

    h1 {
        @include fluid-font($min-width,$max-vw,35px,78px);
        line-height: 1;
        max-width: 696px;
        margin: 0 auto;

        @include media-breakpoint-down(lg) {
            text-align: center;
        }
    }

    .img-wrap {
        aspect-ratio: 1/1;
        max-width: 448px;
        margin: 0 auto;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 50%;
        }
    }

    .content {
        color: rgba(12,12,12,.5);

        h5, .h4, h4 {
            font-size: rem(22px);
            strong {
                color: $primary-color;
            }
        }

        .h3 {
            font-size: rem(24px);
            strong {
                color: $primary-color;
            }
        }

        .h2 {
            color: rgba(12,12,12,.5);
            font-size: rem(26px);
            strong {
                color: $primary-color;
            }
        }

        p {
            //margin-top: 22px;
            margin-bottom: 0;
            line-height: 1.43;
            strong {
                color: $primary-color;
            }
        }

        /*List styled ul*/
        ul {
            margin-top: 10px !important;
            padding: 0;
            list-style: none;
        }

        ul > li {
            position: relative;
            min-height: 29px;
            margin-bottom: 12px;
            padding-left: 43px;
        }

        @media (max-width: 767px) {
            ul > li {
                padding-left: 35px;
            }
        }

        ul > li:before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 29px;
            height: 29px;
            background-repeat: no-repeat;
            background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg viewBox='0 0 29 29' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='14.5' cy='14.5' r='14.5' fill='url(%23paint0_linear_1443_2698)'/%3e%3ccircle cx='14.5' cy='14.5' r='14.5' fill='url(%23paint1_linear_1443_2698)'/%3e%3cpath d='M19.25 15H8.75M15.1667 10.9166L19.25 15L15.1667 10.9166ZM19.25 15L15.1667 19.0833L19.25 15Z' stroke='%23221C18' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3e%3cdefs%3e%3clinearGradient id='paint0_linear_1443_2698' x1='10.78' y1='29' x2='14.853' y2='29' gradientUnits='userSpaceOnUse'%3e%3cstop stop-color='%23F0E4CF'/%3e%3cstop offset='1' stop-color='white' stop-opacity='0'/%3e%3c/linearGradient%3e%3clinearGradient id='paint1_linear_1443_2698' x1='14.5' y1='-3.625' x2='14.5' y2='54.9173' gradientUnits='userSpaceOnUse'%3e%3cstop stop-color='%23E6E3DD'/%3e%3cstop offset='1' stop-color='%23E6E3DD' stop-opacity='0'/%3e%3c/linearGradient%3e%3c/defs%3e%3c/svg%3e ");
        }

        @media (max-width: 767px) {
            ul > li:before {
                top: 2px;
                width: 22px;
                height: 22px;
            }
        }
    }

    .gray-box {
        padding: 40px 60px;
        background: #F9FAFB;
        color: #221C18;
        font-size: 24px;
        font-weight: 450;
        line-height: 28px;

        @include media-breakpoint-down(md) {
            padding: 20px;
            font-size: 20px;
            line-height: 24px;
        }
    }

    .img-cover {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .map {
        height: 100%;
        min-height: 490px;
    }
}

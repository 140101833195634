.purchase-process {
    background: linear-gradient(180deg, #E5E3DD -12.5%, rgba(229, 227, 221, 0.00) 189.37%), linear-gradient(90deg, #F0E4CF 37.17%, rgba(255, 255, 255, 0.00) 51.22%);

    .contact-form {
        position: relative;
        max-width: 596px;
        margin: 0 auto;
        padding: 80px 0;
        // background: linear-gradient(to bottom, rgba(229, 227, 221, 1), rgba(229, 227, 221, 0));

        @include media-breakpoint-down(lg) {
            padding: 60px 0;
        }

        @include media-breakpoint-down(md) {
            padding: 40px 10px;
        }

        /*&:before {
            content: ' ';
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            background: linear-gradient(to right, rgba(240, 228, 207, 1), rgba(255, 255, 255, 0) 50%);
        }*/

        &__wrap {
            position: relative;
            z-index: 2;
        }

        h3 {
            margin-bottom: 24px;
            @include fluid-font($min-width, $max-vw, 22px, 30px);
            font-weight: 450;
            text-align: center;
        }

        input {
            width: 100%;
            height: 58px;
            border: none;
            background-color: $white;
            margin-bottom: 14px;
            border-radius: 30px;
            padding: 0 20px;
            outline: none;
        }

        textarea {
            width: 100%;
            height: 130px;
            border-radius: 24px;
            border: none;
            resize: none;
            outline: none;
            padding: 20px;
            margin-bottom: 14px;
        }

        .file-input {
            position: relative;
            margin-bottom: 14px;

            input[type='file'] {
                margin: 0;
                padding: 20px;
                min-height: 130px;
                color: rgba(12, 12, 12, 0.50);
                text-align: center;
            }

            input[type='file']::file-selector-button {
                display: none;
            }

            &-text {
                position: absolute;
                left: 0;
                right: 0;
                bottom: 20px;
                color: #000;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 23.2px;
                text-align: center;
                pointer-events: none;

                span {
                    color: #1F294C;
                    text-decoration: underline;
                }
            }
        }


        input[type='checkbox'] {
            display: none;

            &:checked + label span {
                border-color: transparent;
                background-color: $primary-color;

                &:before {
                    opacity: 1;
                }
            }
        }

        label[for='gdpr'] {
            cursor: pointer;
            color: #838384;
            position: relative;
            padding-left: 35px;

            a {
                color: $primary-color;
                text-decoration: underline !important;
            }

            span {
                display: inline-block;
                width: 22px;
                height: 22px;
                border: 1px solid #838384;
                position: absolute;
                left: 0;
                top: 0;
                border-radius: 8px;

                &:before {
                    content: ' ';
                    width: 13px;
                    height: 13px;
                    background: url('/img/check.svg');
                    position: absolute;
                    top: 3px;
                    left: 3px;
                    opacity: 0;
                }
            }
        }

        button {
            margin-top: 25px;
            width: 100%;
            height: 58px;
            padding: 0 45px;
            text-transform: uppercase;
            letter-spacing: 1.2px;
            color: $white;
            background-color: $text-color;
            border-radius: 30px;
            font-weight: 500;
            font-size: rem(14px);

            &:hover {
                background-color: lighten($text-color, 10%);

                img {
                    transform: translateX(10px) translateY(-2px);
                }
            }

            img {
                transition: transform .2s ease-in-out;
                padding-left: 3px;
                transform: translateY(-2px);
            }
        }
    }
}

.purchase-steps {
    display: flex;
    justify-content: center;
    gap: 16px;

    @include media-breakpoint-down(lg) {
        gap: 10px;
    }

    @include media-breakpoint-down(md) {
        gap: 5px;
    }

    @include media-breakpoint-down(sm) {
        flex-direction: column;
        padding-inline: 30px;
    }

    &-wrapper {
        padding: 60px 0;
        background: #F4F3F1;

        @include media-breakpoint-down(lg) {
            padding: 45px 0;
        }

        @include media-breakpoint-down(md) {
            padding: 30px 0;
        }

        h2 {
            color: #221C18;
            @include fluid-font($min-width, $max-vw, 28px, 36px);
            font-weight: 500;
            line-height: normal;
            text-align: center;
        }
    }
}

.purchase-step {
    width: 280px;
    display: flex;
    align-items: center;

    @include media-breakpoint-between(sm, lg) {
        flex-direction: column;
    }

    @include media-breakpoint-down(sm) {
        width: 100%;

        &:not(:last-child) {
            margin-bottom: 8px;
        }
    }

    &-number {
        width: 70px;
        height: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 15px;
        background: linear-gradient(180deg, #E5E3DD -12.5%, rgba(229, 227, 221, 0.00) 189.37%), linear-gradient(90deg, #F0E4CF 37.17%, rgba(255, 255, 255, 0.00) 51.22%);
        border-radius: 50%;
        color: #221C18;
        font-size: 36px;
        font-weight: 450;
        line-height: normal;

        @include media-breakpoint-down(lg) {
            width: 50px;
            height: 50px;
            font-size: 22px;
        }

        @include media-breakpoint-between(sm, lg) {
            margin-bottom: 10px;
            margin-right: 0;
        }

        @include media-breakpoint-down(md) {
            width: 40px;
            height: 40px;
            font-size: 18px;
        }
    }

    &-name {
        flex-basis: 0;
        flex-grow: 1;
        color: #221C18;
        font-size: 20px;
        font-weight: 400;
        line-height: 26px;

        @include media-breakpoint-down(lg) {
            font-size: 18px;
            line-height: 22px;
        }

        @include media-breakpoint-between(sm, lg) {
            text-align: center;
        }

        @include media-breakpoint-down(md) {
            font-size: 16px;
            line-height: 19px;
        }
    }
}

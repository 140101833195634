ol.breadcrumb {
    padding: 32px 0;
    margin-bottom: 0;

    li:before {
        opacity: .6;
        font-weight: 400;
    }

    li:first-child a {
        display: inline-block;
        width: 13px;
        height: 13px;
        position: relative;
    }

    li:first-child a:before {
        content: " ";
        width: 13px;
        height: 13px;
        background: url('/img/home-icon.svg');
        position: absolute;
        top: 0;
        left: 0;
    }
    a {
        color: #9B9DA1;
        font-weight: 500;

    }
    .active {
        color: $primary-color;
        font-weight: 500;
    }
}

footer {
    .footer-top {
        background-color: $nav-color;
        padding: 70px 0;
        nav {
            a {
                font-size: rem(15px);
                text-transform: uppercase;
                font-weight: 500;
                color: #BCBAB9;
                letter-spacing: 1px;
                @include media-breakpoint-down(sm) {
                    font-size: rem(13px);
                }
                &:hover {
                    color: darken(#BCBAB9,20%);
                }
            }
        }
    }

    .footer-bottom {
        background-color: #29231E;
        padding: 20px 0;
        p {
            margin-bottom: 0;
            font-size: rem(14px);
            color: rgba($white,.25);
        }
    }
}

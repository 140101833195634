.who-we-are {
    padding: 80px 0;

    @include media-breakpoint-down(lg) {
        padding: 60px 0;
    }

    @include media-breakpoint-down(md) {
        padding: 40px 0;
    }

    h1 {
        text-align: center;
        @include fluid-font($min-width,$max-vw,35px,78px);
        line-height: 1;
        max-width: 696px;
        margin: 0 auto;
    }

    .img-wrap {
        aspect-ratio: 1/1;
        max-width: 448px;
        margin: 0 auto;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 50%;
        }
    }

    //h4 {
    //    text-align: center;
    //    margin: 65px auto 38px auto;
    //    font-size: rem(18px);
    //    max-width: 424px;
    //    color: rgba(12,12,12,.5);
    //    font-weight: 400;
    //    line-height: 1.65;
    //}

    .content {
        color: rgba(12,12,12,.5);

        h5, .h4, h4 {
            font-size: rem(22px);
            strong {
                color: $primary-color;
            }
        }

        .h3 {
            font-size: rem(24px);
            strong {
                color: $primary-color;
            }
        }

        .h2 {
            color: rgba(12,12,12,.5);
            font-size: rem(26px);
            strong {
                color: $primary-color;
            }
        }

        p {
            margin-top: 22px;
            margin-bottom: 0;
            line-height: 1.43;
            strong {
                color: $primary-color;
            }
        }
    }

    .timeline {
        width: 100px;
        margin: 0 auto;
        padding-bottom: 250px;
        position: relative;
        @include media-breakpoint-down(sm) {
           width: 100%;
        }
        @include media-breakpoint-down(lg) {
            margin: 80px auto 0 auto;
        }
        &:before {
            content: " ";
            width: 2px;
            height: 100%;
            position: absolute;
            left: 50%;
            margin-left: -1px;
            top: 0;
            background-color: #D9D9D9;
        }

        &__item {
            position: relative;
            margin-bottom: 40px;
            @include media-breakpoint-down(lg) {
                margin-bottom: 100px;
            }
            @include media-breakpoint-down(sm) {
                text-align: center;
            }
            &:before {
                content: " ";
                width: 51px;
                height: 2px;
                background-color: #D9D9D9;
                position: absolute;
                top: 50%;
                margin-top: -1px;
                @include media-breakpoint-down(sm) {
                   display: none;
                }
            }
            &:nth-child(odd) {
                img {
                    transform: translateX(calc(-100% - 20px));
                    @include media-breakpoint-down(sm) {
                        transform: translateX(0);
                    }
                }
                .timeline__item__content {
                    left: calc(100% + 20px);
                    @include media-breakpoint-down(sm) {
                        left: 0;
                    }
                }
                &:before {
                    left: 0;
                }
            }

            &:nth-child(even) {
                img {
                    transform: translateX(120px);
                    @include media-breakpoint-down(sm) {
                        transform: translateX(0);
                    }
                }

                .timeline__item__content {
                    right: calc(100% + 20px);
                    @include media-breakpoint-down(sm) {
                       right: 0;
                    }
                }
                &:before {
                    right: 0;
                }
            }
            img {
                border-radius: 50%;
                width: 284px;
                height: 284px;
                @include media-breakpoint-down(lg) {
                    width: 230px;
                    height: 230px;
                }
            }

            &__content {
                position: absolute;
                width: 405px;
                top: 50%;
                transform: translateY(-50%);
                @include media-breakpoint-down(lg) {
                    width: 200px;
                }
                @include media-breakpoint-down(sm) {
                    position: relative;
                    width: 100%;
                    top: 0;
                    transform: translateY(0);
                    padding: 30px 0;
                    background-color: #F9FAFB;
                }
                h5 {
                    font-size: rem(20px);
                    font-weight: 600;
                }

                p {
                    line-height: 1.43;
                    margin-bottom: 0;
                }
            }
        }
    }
}

header {
    padding: 45px 28px;
    background-color: #f9fafb;
    transition: all 0.2s ease-in-out;
    .logo a {
        display: block;
        line-height: 1;
    }
    &.hide {
        transform: translateY(-100%);
    }
    &.smaller {
        padding: 25px 28px;
        box-shadow: 0 0 10px 0 rgba($black, 0.15);
    }
    @include media-breakpoint-down(xl) {
        padding: 20px 0;
        &.smaller {
            padding: 20px 0;
        }
        .logo img {
            width: 180px;
        }
    }
    .cart {
        position: relative;
        &:hover {
            opacity: 0.6;
        }
        span {
            position: absolute;
            top: -10px;
            right: -10px;
            display: flex;
            width: 14px;
            height: 14px;
            background-color: $primary-color;
            color: $white;
            font-size: rem(10px);
            border-radius: 50%;
            align-items: center;
            justify-content: center;
            font-weight: 600;
        }
    }

    nav {
        .close {
            position: absolute;
            top: 30px;
            right: 30px;
        }
        @include media-breakpoint-down(xl) {
            position: fixed;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            width: 100vw;
            height: 100vh;
            background-color: $primary-color;
            z-index: 10;
            display: flex;
            align-items: center;
            justify-content: center;
            transform: translateX(100%);
            transition: transform 0.2s ease-in-out;
            &.active {
                transform: translateX(0);
            }
            ul {
                flex-direction: column;
                align-items: center;
                a {
                    color: $white;
                    font-size: rem(25px);
                }
            }
        }
        li {
            @include media-breakpoint-up(lg) {
                opacity: 1 !important;
                transform: translateY(0) !important;
            }
        }

        a {
            text-transform: uppercase;
            font-weight: 500;
            color: $nav-color;
            position: relative;
            letter-spacing: 1.2px;
            &:after {
                content: " ";
                width: 0;
                height: 1px;
                background-color: $nav-color;
                left: 0;
                bottom: 0;
                position: absolute;
                transition: width 0.2s ease-in-out;
            }
            &:hover {
                color: $nav-color;
                &:after {
                    width: 100%;
                }
            }
        }
    }

    .container {
        position: relative;
    }
}

.header-right {
    position: relative;
}

.open-mobile-menu {
    height: 25px;
}

/**
  SEARCH ICON
 */

.search-box {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    @include media-breakpoint-between(sm, xl) {
        right: 56px;
    }
    @include media-breakpoint-down(sm) {
        position: relative;
        width: 100%;
        top: 0;
        transform: translateY(0);
        margin-top: 11px;
        background-color: $white;
        border-radius: 6px;
        box-shadow: 0 3px 3px 0 rgba($black, 0.15);
        &.hidden {
            display: none;
        }
    }

    input {
        background: none;
        border: none;
        outline: none;
        width: 28px;
        min-width: 0;
        padding: 0;
        z-index: 1;
        position: relative;
        line-height: 18px;
        margin: 5px 0;
        font-size: 15px;
        -webkit-appearance: none;
        transition: all 0.6s ease;
        cursor: pointer;
        color: $black;
        @include media-breakpoint-down(sm) {
            width: 100%;
            min-width: 100%;
            margin-bottom: 0;
            height: 45px;
            padding: 0 15px;
            background-color: transparent !important;
            font-size: rem(18px);
            margin-top: 0;
        }
        & + div {
            position: relative;
            height: 28px;
            width: 100%;
            margin: -28px 0 0 0;
            svg {
                display: block;
                position: absolute;
                height: 28px;
                width: 160px;
                right: 0;
                top: 0;
                fill: none;
                stroke: $primary-color;
                stroke-width: 1.5px;
                stroke-dashoffset: 212.908 + 59;
                stroke-dasharray: 59 212.908;
                transition: all 0.6s ease;
                @include media-breakpoint-down(sm) {
                    display: none;
                }
            }
        }
        &:not(:placeholder-shown),
        &:focus {
            width: 100%;
            padding: 0 4px;
            cursor: text;
            background-color: $grey-bg;
            @include media-breakpoint-down(sm) {
                padding: 0 15px;
            }
            @include media-breakpoint-up(sm) {
                & + div {
                    svg {
                        stroke-dasharray: 150 212.908;
                        stroke-dashoffset: 300;
                    }
                }
            }
        }

        &[type="search"]::-webkit-search-decoration,
        &[type="search"]::-webkit-search-cancel-button,
        &[type="search"]::-webkit-search-results-button,
        &[type="search"]::-webkit-search-results-decoration {
            -webkit-appearance: none;
        }
    }

    .search-wrap {
        overflow-y: auto;
        max-height: calc(100vh - 5rem);
        position: absolute;
        top: 100%;
        width: 100%;
        min-width: 30rem;
        right: 0;
        z-index: 10;
        background-color: $grey-bg;
        box-shadow: 0px 13px 16px 0px rgba(0, 0, 0, 0.1);
        -webkit-box-shadow: 0px 13px 16px 0px rgba(0, 0, 0, 0.1);
        -moz-box-shadow: 0px 13px 16px 0px rgba(0, 0, 0, 0.1);
        @media (max-width: 558px) {
            min-width: 100%;
        }
        .search-results-wrap {
            width: 100%;
            height: 100%;
            position: relative;

            .search-link {
                &:hover {
                    .search-result-title {
                        font-weight: bold;
                    }
                }
            }
            .search-result-title {
                line-height: normal;
                font-size: 1.125rem;
                font-weight: 600;
                color: #221c18;
            }
            .search-result-desc {
                font-size: 1rem;
                color: #bdbdbd;
                font-weight: 500;
                line-height: normal;
            }
            .search-result-price {
                font-weight: 500;
                color: #d42c2c;
                font-size: 0.875rem;
                line-height: normal;

                .old-price {
                    font-size: 0.75rem;
                    color: #bdbdbd;
                    text-decoration: line-through;
                    padding-left: 5px;
                }
            }
            .search-img {
                width: 10rem;
                height: 100%;
                min-height: 100%;
                max-height: 100%;
                overflow: hidden;
                img {
                    min-height: 100%;
                    width: 100%;
                    max-width: 100%;
                    height: auto;
                    object-fit: cover;
                }
            }
            .search-title {
                color: lighten($text-color, 30%);
                text-transform: uppercase;
                font-size: 0.8rem;
                font-weight: bold;
            }
            .border-bottom-light {
                border-bottom: 1px solid $grey-bg;
            }
            .border-bottom {
                border-bottom: 1px solid lighten($text-color, 10%);
            }

            .label {
                font-size: 0.75rem;
                text-transform: uppercase;
                color: #fff;
                letter-spacing: 1px;
                font-weight: 600;
                padding: 0 10px;
                display: block;
                border-radius: 30px;

                &.orange {
                    background-color: #ffa800;
                }
            }
        }
    }
}

.open-search {
    margin-right: 10px;
}
